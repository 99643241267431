const redirectTo =
  (link, target = '_parent') =>
    () => {
      if (typeof window !== 'undefined') {
        window.open(link, target)
      }
    }

const getPortalHost = () => {
  if (typeof window !== 'undefined') {
    const { origin, hostname } = window.location
    const mappingList = {
      'www.mlytics.co': 'https://portal.mlytics.co',
      'www.mlytics.com': 'https://portal.mlytics.com'
    }
    const portalHost = origin === 'http://localhost:8000' ? 'https://portal.mlytics.co' : mappingList[hostname]
    return portalHost
  }
}

const getLoginAsUrl = (customerId, organizationId, jwtToken) => {
  const portalHost = getPortalHost()

  return `${portalHost}/loginas/${customerId}/${organizationId}/${jwtToken}`
}

const sendDataLayerEvent = (payload) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(payload)
}

const redirectToPortal = () => {
  if (typeof window !== 'undefined') {
    const portalHost = getPortalHost()
    const { pathname, search } = window.location
    const linkArr = [portalHost, pathname]
    if (search) linkArr.push(search)
    const link = linkArr.json('')
    window.location.replace(link)
  }
}

const formatNumberWithCommas = (numString) => {
  const result = Number(numString)

  if (isNaN(result)) return null
  return result.toLocaleString('en-US')
}

export { redirectTo, getPortalHost, getLoginAsUrl, sendDataLayerEvent, redirectToPortal, formatNumberWithCommas }
