import React, { useLayoutEffect } from 'react'
import { graphql } from 'gatsby'

import { redirectToPortal } from 'utils'

const VerifyEmailPage = () => {
  useLayoutEffect(() => {
    redirectToPortal()
  }, [])
  return <div></div>
}

export default VerifyEmailPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
